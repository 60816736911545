@import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah');
.section-animation {
  opacity: 0;
}

.replay-img,
.print-img {
  height: 32px;
  width: 32px;
  display: inline-block;
  transform: translate(8px, 10px);
  background-repeat: no-repeat;
  background-size: 377px 190px;
  margin-right: 25px;
}

.replay-img {
  background-position: -31px -49px;
}

.print-img {
  background-position: -106px -47px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi) {
  .replay-img,
  .print-img {
    background-size: 188.5px 95px;
  }
  .replay-img {
    background-position: -17px -46px;
  }
  .print-img {
    background-position: -53px -47.5px;
  }
}

.section-animation .state-control {
  text-align: center;
  opacity: 1;
  margin: 1.5em 0;
}

.section-animation .card {
  color: #fff;
  border-radius: 5px;
  width: 90%;
  padding-top: 50%;
  position: relative;
  max-width: 900px;
  max-height: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

@media screen and (min-width: 991px) {
  .section-animation .card {
    max-height: 400px;
    height: 450px;
    width: 900px;
    padding: 450px 0 0 0;
  }
}

@media screen and (max-width: 940px) {
  .section-animation .card {
    padding-top: 134%;
    min-height: 348px;
    min-width: 261px;
  }
}

.card-control {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 3em 0 3em 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: fade-in-down 1s forwards 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 940px) {
  .card-control {
    flex-direction: column;
  }
}

.flip-container {
  -webkit-perspective: 4000px;
  -moz-perspective: 4000px;
  perspective: 4000px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flipper {
  z-index: 1;
  transition: 1s;
  transform-style: preserve-3d;
  perspective: 4000px;
  width: 100%;
  height: 100%;
}

.flip-one-side,
.flip-two-side,
.flip-three-side {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00c0e2;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-one-side {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-one-side p {
  font-size: 1.2857em;
  font-weight: 100;
}

.flip-one-side .card-name {
  text-transform: uppercase;
  font-weight: 600;
}

@media screen and (max-width: 940px) {
  .flip-one-side,
  .flip-two-side,
  .flip-three-side {
    min-height: 348px;
    min-width: 261px;
    height: auto;
  }
}

@media screen and (max-width: 440px) {
  .flip-one-side,
  .flip-two-side,
  .flip-three-side {
    min-height: 440px;
    min-width: 261px;
    height: auto;
  }
  .flip-two-side {
    height: auto !important;
  }
}

.flip-two-side {
  background-color: #d74b34;
  z-index: 4;
  transform: rotateY(180deg);
}

@media screen and (max-width: 940px) {
  .flip-two-side .card-info {
    width: 100%;
    height: 50%;
    display: block;
    float: none;
    padding-left: 0;
  }
  .flip-two-side .card-price h3 {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0.8em;
    margin-bottom: 0;
    font-size: 1em;
  }
}

@media screen and (max-width: 380px) {
  .flip-two-side .card-coord h3 {
    margin-bottom: 10px;
  }
  .flip-two-side .card-coord p {
    line-height: 1em;
  }
}

.flip-three-side {
  z-index: 5;
  transform: rotateY(180deg);
}

.flip-three-side .message-container {
  display: flex;
  font-family: 'Gloria Hallelujah', cursive;
  background-color: #fff;
  color: #000;
  height: 100%;
  text-align: left;
}

.flip-three-side .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  word-wrap: break-word;
  white-space: pre-line;
}

.flip-three-side.only-text .message-container {
  width: 100%;
  height: 100%;
  float: none;
  display: flex;
  flex-direction: column;
}

.flip-three-side.only-text .message {
  align-content: center;
  padding-left: 20%;
  padding-right: 20%;
}

.flip-three-side.only-image .message-container {
  width: 0;
  height: 0;
  display: none;
}

@media screen and (max-width: 940px) {
  .flip-three-side .row {
    position: relative;
    height: 100%;
  }
  .flip-three-side .message-container {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    float: none;
    padding: 15px;
  }
  .flip-three-side h2,
  .flip-three-side p {
    margin: 0;
  }
  .flip-three-side h2 {
    font-size: 1.45rem;
  }
  .flip-three-side p {
    font-size: 0.9rem;
  }
}

.animated.section-animation {
  opacity: 1;
  animation: fade-in forwards 1s;
}

.animated.first-flip.ttreplay .bird {
  animation: bird-touch forwards 1s, bird-touch-reverse forwards 1s 1s;
  animation-timing-function: ease, ease-in-out;
}

.animated.first-flip .bird {
  animation: fly-in forwards 1.5s, bird-touch forwards 1s 1.5s,
    bird-touch-reverse forwards 1s 2.5s;
  animation-timing-function: ease-in-out, ease, ease-in-out;
}

.animated.showBird .bird {
  left: -51px;
  animation: none;
}

.animated.test .bird {
  animation: bird-touch forwards 1s 1.5s, bird-touch-reverse forwards 1s 2.5s;
  animation-timing-function: ease, ease-in-out;
}

.animated.first-flip .flip-one-side {
  animation: first-flip 1s forwards;
  animation-delay: 1.5s;
}

.animated.first-flip .flip-two-side {
  animation: second-flip 1s forwards;
  animation-delay: 1.5s;
}

.animated.second-flip .bird {
  animation: bird-touch-second 1s forwards,
    bird-touch-second-reverse forwards 1s 1s;
}

.animated.second-flip .flip-two-side {
  z-index: 3;
  animation: first-flip 1s both;
}

.animated.second-flip .flip-three-side {
  z-index: 2;
  animation: second-flip 1s forwards;
}

.animated.ttreplay .flip-three-side {
  z-index: 4;
  animation: first-flip 1s both;
}

.animated.ttreplay .flip-one-side {
  z-index: 4;
  animation: second-flip 1s both;
}

.show-controls .card-control {
  animation: fade-in-down 1s forwards 1.5s;
}

.animated.ttreplay .card-control {
  opacity: 1;
  animation: fade-out 0.2s forwards;
}

.animated.second-flip .state-control {
  animation: fade-out-down 0.5s forwards 1s;
}

.animation-to-title {
  font-size: 38px;
}

.clickthrough {
  pointer-events: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade-in-down {
  0% {
    transform: translateY(-100px);
    opacity: 0;
    height: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    height: auto;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
    height: auto;
    padding: 3em 0 3em 0;
  }
  100% {
    opacity: 0;
    margin: 0;
    height: 0;
  }
}

/* Card rotate first time */

@keyframes first-flip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

/* Card rotate second time */

@keyframes second-flip {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animated .card-control button {
  visibility: hidden;
}

.animated.show-controls .card-control button {
  visibility: visible;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:active:focus,
.btn:focus {
  outline: none;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-primary {
  color: #fff;
  background-color: #17bfdd;
  border-color: #17bfdd;
}

.btn-primary:active {
  background-image: none;
}

.btn-clear {
  background-color: transparent;
  color: #ffffff !important;
}

.btn-clear img {
  padding-right: 10px;
  padding-bottom: 5px;
}

.btn-clear:hover,
.btn-clear:focus,
.btn-clear:active {
  color: #17bfdd;
  text-shadow: none;
}

.section-button {
  height: 67px;
  line-height: 67px;
  min-width: 230px;
  padding: 0 1.9rem;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.replay-img,
.print-img {
  height: 32px;
  width: 32px;
  background-image: url('../../../assets/img/controls-icons.png');
  display: inline-block;
  transform: translate(8px, 10px);
  background-repeat: no-repeat;
  background-size: 377px 190px;
  margin-right: 25px;
}

.replay-img {
  background-position: -31px -49px;
}

.replay-btn {
  margin-right: 10%;
}

.print-img {
  background-position: -106px -47px;
}
