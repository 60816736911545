body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: relative;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.print-goalcard {
  max-width: 820px;
  margin: auto;
  background-color: #11c1e1;
  height: 100vh;
}

.print-goalcard-wrapper {
  height: 0;
  padding-top: 129.3%;
  position: relative;
}

.print-goalcard-inner {
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 3%;
}

.print-goalcard-header {
  padding: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.print-goalcard-logo-wrapper {
  flex-basis: 46.5%;
}

.print-goalcard-logo {
  max-width: 100%;
}

.print-goalcard-heading {
  margin: 0;
  font-size: 6vw;
  flex-shrink: 0;
  margin-left: 15%;
}

.print-goalcard-pic {
  height: 30%;
  text-align: center;
  padding: 4% 0;
  background-size: cover;
  background-image: url('../../../../../assets/img/background_print.png');
}

.print-goalcard-pic img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.print-goalcard-body {
  padding: 3% 3% 0;
  display: flex;
}

.print-goalcard-body-text {
  flex-basis: 63%;
  padding-right: 3%;
}

.print-goalcard-body-info {
  padding-bottom: 2%;
  border-bottom: 1px solid #b2b2b2;
}

.print-goalcard-body-info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4%;
  font-size: 2.3vw;
}

.print-goalcard-body-info-item-label {
  flex-basis: 16%;
}

.print-goalcard-body-info-item-value {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.print-goalcard-body-photo {
  flex-basis: 37%;
  text-align: center;
}

.print-goalcard-body-congrats {
  padding: 5% 0 3%;
}

.print-goalcard-body-congrats-heading {
  font-size: 2.3vw;
  margin: 0;
}

.print-goalcard-body-congrats-par {
  font-size: 2vw;
  line-height: 1.7;
  margin: 3% 0;
}

.print-goalcard-body-congrats-sign {
  font-size: 2vw;
  font-style: italic;
}

.print-goalcard-faq {
  padding: 0 3% 2%;
  font-size: 2vw;
}

.print-goalcard-faq-q {
  color: #06bedf;
  text-transform: uppercase;
  margin: 2% 0;
}

.print-goalcard-faq-a {
  margin: 0 0 2% 0;
}

.print-goalcard.no-photo .print-goalcard-body-photo,
.print-goalcard.no-photo .print-goalcard-body-congrats {
  display: none;
}

.print-goalcard.no-photo .print-goalcard-faq {
  padding: 30px 30px 10px;
}

.print-goalcard.no-photo .print-goalcard-pic {
  margin: 100px 0;
}

.print-goalcard-full-extended-container {
  height: 1280px !important;
}

.print-goalcard-medium-extended-container {
  height: 1150px !important;
}

@media (min-width: 820px) {
  .print-goalcard {
    font-size: 1rem;
  }
  .print-goalcard-heading {
    font-size: 4em;
  }
  .print-goalcard-body-info-item,
  .print-goalcard-body-congrats-heading {
    font-size: 1.3em;
  }
  .print-goalcard-body-congrats-par,
  .print-goalcard-body-congrats-sign,
  .print-goalcard-faq {
    font-size: 1em;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
  }
}

.print-goalcard-min-height {
  min-height: 100%;
}

.uploaded-img {
  max-width: 270px;
  max-height: 300px;
}
